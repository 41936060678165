import { usePublicationEditorStore } from '~/stores/publicationEditor';

export const useMediumEditorDofollowAnchorExtension = (vm, MediumEditor) => {
    if (!vm) throw new Error('useMediumEditorDofollowAnchorExtension requires "this" object');
    if (!MediumEditor) throw new Error('useMediumEditorDofollowAnchorExtension requires "MediumEditor" object');
    
    const publicationEditor = usePublicationEditorStore();

    return MediumEditor.extensions.anchor.extend({
        contentDefault: '<span class="icon-ic-link icon mr-lg-h"></span> <span class="d-none d-lg-inline">'+vm.$t('link')+'</span>',
        classList: ['mx-lg-1'],
        getTemplate: function () {
            const allowDofollowLinks = this.getAllowDofollowLinks(); // Dynamically retrieve the value

            var template = [
                '<div class="d-flex align-items-center px-h pt-h mb-1h">',
                '<input type="text" class="medium-editor-toolbar-input" placeholder="', vm.$t('publicationEditor.linkForm.linkPlaceholder'), '">',
                '<button class="medium-editor-toolbar-save btn-primary icon-ic-tick btn-sm"></button>',
                '<button class="medium-editor-toolbar-close btn-bg elevate icon-ic-dismiss btn-sm d-none"></button>',
                '</div>',

                '<div class="d-flex align-items-center justify-content-between px-h">',
                `<label class="checkbox f-normal dofollow-checkbox-wrapper" >`,
                `<input type="checkbox" class="medium-editor-dofollow-checkbox" ${allowDofollowLinks ? '' : 'disabled'}>`,
                '<div class="box elevate align-self-baseline"><span class="icon-ic-tick"></span></div>', 
                `<div class="text">${vm.$t('publicationEditor.linkForm.toggle')}</div>`,
                '</label>',
                `<button id="dofollow-btn-${this.getEditorId()}" class="b-link">`, vm.$t('publicationEditor.linkForm.about'), '</button>',
                '</div>',
                '<div class="dofollow-status-container">',
                this.generateStatus(),
                '</div>'
            ];


            if (this.customClassOption) {
                // fixme: expose this `Button` text as a formLabel property, too
                // and provide similar access to a `fa-` icon default.
                template.push(
                    '<div class="medium-editor-toolbar-form-row">',
                    '<input type="checkbox" class="medium-editor-toolbar-anchor-button">',
                    '<label>',
                    this.customClassOptionText,
                    '</label>',
                    '</div>'
                );
            }

            template.push('</div>')

            return template.join('');
        },

        generateStatus: function () {
            const allowDofollow = this.getAllowDofollowLinks();
            const dofollowTokens = this.getAvailableActivations();

            let template = [];

            if (allowDofollow) {
                template = [
                    '<div class="p-1h mx-n1 mb-n1 mt-1h bg-color-green-dim dofollow-status f-small">',
                    vm.$t('publicationEditor.linkForm.activeText'),
                    '</div>'
                ]
            } else if (dofollowTokens > 0) {
                template = [
                    '<div class="p-1h mx-n1 mb-n1 mt-1h bg-color-green-dim dofollow-status">',
                    '<div class="fw-700 f-small">', vm.$t('publicationEditor.linkForm.activateText'),'</div>',
                    '<div class="d-flex align-items-center justify-content-between mt-1">',
                    '<span class="f-small">', vm.$t('publicationEditor.linkForm.available'), '<span class="fw-700 color-green"> ', vm.$tc('publicationEditor.linkForm.activations', dofollowTokens), '</span></span>',
                    `<button id="apply-dofollow-btn-${this.getEditorId()}" class="btn-bg elevate btn-sm">`, vm.$t('publicationEditor.linkForm.activate'), '</button>',
                    '</div>'
                ]
            } else {
                template = [
                    '<div class="p-1h mx-n1 mb-n1 mt-1h bg-color-purple-dim dofollow-status">',
                    '<div class="fw-700 f-small">', vm.$t('publicationEditor.linkForm.activateText'),'</div>',
                    '<div class="d-flex align-items-center justify-content-between mt-1">',
                    '<span class="f-small">', vm.$t('publicationEditor.linkForm.available'), '<span class="fw-700 color-purple"> ', vm.$tc('publicationEditor.linkForm.activations', dofollowTokens), '</span></span>',
                    `<button id="purchase-dofollow-btn-${this.getEditorId()}" class="btn-bg elevate btn-sm">`, vm.$t('publicationEditor.linkForm.purchase'), '</button>',
                    '</div>'
                ]
            }

            return template.join('');
        },

        getFormOpts: function () {
            return {
                value: this.getInput().value.trim(),
                target: '_blank',
                dofollow: this.getDofollowCheckbox().checked
            };
        },

        getAllowDofollowLinks: function () {
            return publicationEditor.post?.allowDofollowLinks;
        },

        getAvailableActivations: function () {
            return publicationEditor.dofollowTokens;
        },

        // Override the `showForm` method to attach an event listener
        showForm: function (opts) {
            // Update status part of the form
            const statusContainer = this.getForm().querySelector('.dofollow-status-container');
            if (statusContainer) {
                statusContainer.innerHTML = this.generateStatus();
            }

            MediumEditor.extensions.anchor.prototype.showForm.apply(this, arguments);


            const allowDofollowLinks = this.getAllowDofollowLinks();
            const dofollowCheckbox = this.getDofollowCheckbox();
            const currentLink = this.getSelectedAnchorElement();
            const rel = currentLink?.getAttribute('rel');
            

            if (rel?.indexOf('nofollow') < 0 && allowDofollowLinks) dofollowCheckbox.checked = true;
            else dofollowCheckbox.checked = false;

            dofollowCheckbox.disabled = !allowDofollowLinks;

            const dofollowBtn = this.getDofollowBtn();
            if (dofollowBtn) {
                this.on(dofollowBtn, 'click', this.openAboutDofollow.bind(this));
            }

            const purchaseDofollowBtn = this.getPurchaseDofollowBtn();
            if (purchaseDofollowBtn) {
                this.on(purchaseDofollowBtn, 'click', this.openPurchaseDofollow.bind(this));
            }

            const applyDofollowBtn = this.getApplyDofollowBtn();
            if (applyDofollowBtn) {
                this.on(applyDofollowBtn, 'click', this.handleDofollowApply.bind(this));
            }

            // Listen for the toolbar to hide and remove the event listener
            this.base.subscribe('hideToolbar', this.removeEventListeners.bind(this));

            // Focus anchor input
            const input = this.getInput();
            input.focus();
        },

        openPurchaseDofollow: function () {
            this.base.checkSelection();

            vm.$modalityStore.openModal({
                type: 'drawer',
                name: 'purchase-dofollow'
            });
        },

        openAboutDofollow: function () {
            this.base.checkSelection();

            vm.$modalityStore.openModal({
                type: 'drawer',
                name: 'dofollow-help'
            });
        },

        handleDofollowApply () {
            if(!window.confirm(vm.$t('publicationEditor.linkForm.dofollowConfirm'))) return;
            
            let id = publicationEditor.post.original || publicationEditor.post.id;

            vm.$axios.$post('/publication/applyDofollow', {id})
            .then(res => {
                this.showForm()
            })
            .catch(err => {
                vm.$toastStore.create({
                    title: vm.$t('notification.error'),
                    text: vm.$t(err.message) || '',
                    icon: 'icon-ic-dismiss'
                })
                console.log(err)
            })
        },

        // Helper method to get the dofollow checkbox
        getDofollowCheckbox: function () {
            return this.getForm().querySelector('.medium-editor-dofollow-checkbox');
        },

        getDofollowBtn: function () { 
            return document.querySelector(`#dofollow-btn-${this.getEditorId()}`)
        },

        getPurchaseDofollowBtn: function () {
            return document.querySelector(`#purchase-dofollow-btn-${this.getEditorId()}`);
        },

        getApplyDofollowBtn: function () {
            return document.querySelector(`#apply-dofollow-btn-${this.getEditorId()}`);
        },

        getSelectedAnchorElement: function () {
            // Restore the saved selection context
            this.base.restoreSelection();

            // Get the selected parent element
            let selectedElement = this.base.getSelectedParentElement();

            // Traverse up the DOM tree to find an anchor element
            while (selectedElement && selectedElement.nodeType === Node.ELEMENT_NODE) {
                if (selectedElement.tagName === 'A') {
                    return selectedElement;
                }
                selectedElement = selectedElement.parentNode;
            }

            return null;
        },

        // Override the `completeFormSave` to apply the dofollow attribute
        completeFormSave: function (opts) {
            // Call the original `completeFormSave` method
            MediumEditor.extensions.anchor.prototype.completeFormSave.apply(this, arguments);

            const anchor = this.getSelectedAnchorElement();

            if (anchor) {
                // Update existing anchor
                if (opts.dofollow) {
                    anchor.setAttribute('rel', 'noopener noreferer');
                } else {
                    anchor.setAttribute('rel', 'noopener noreferer ugc nofollow');
                }
            }

            // Fire our custom editableInput event
            let currentEditor = MediumEditor.selection.getSelectionElement(this.base.options.contentWindow);
            let customEvent = this.base.options.ownerDocument.createEvent('HTMLEvents');
            customEvent.initEvent('input', true, true, this.base.options.contentWindow);
            this.base.events.triggerCustomEvent('editableInput', customEvent, currentEditor);
        },


        removeEventListeners: function () {
            const dofollowBtn = this.getDofollowBtn();
            if (dofollowBtn) {
                dofollowBtn.removeEventListener('click', this.openAboutDofollow);
            }

            const purchaseDofollowBtn = this.getPurchaseDofollowBtn();
            if (purchaseDofollowBtn) {
                purchaseDofollowBtn.removeEventListener('click', this.openPurchaseDofollow);
            }

            // Unsubscribe from the hideToolbar event
            this.base.unsubscribe('hideToolbar', this.removeEventListeners.bind(this));
        }
    });
}