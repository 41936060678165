import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
import { useTimeStore } from './time';
import { useCookieStore } from './cookie';

/**
 * @typedef Sale
 * @property {Date} start sale start date
 * @property {Date} end sale end date
 * @property {string} name sale name
 * @property {string} slug sale slug
 * @property {string?} utmLabel value of utm_campaign UTM tag. Defaults to sale slug
 * @property {string?} cssClass CSS class to be added to the banner and menu items. Defaults to sale slug
 * @property {string?} menuLabel sale menu button label
 */

export var useSaleStore = defineStore({
  id: 'sale',
  /**
   * @returns {{
   *     sales: Sale[],
   * }}
   */
  state: function state() {
    return {
      sales: [{
        start: new Date('2024-12-01T22:00:00.000Z'),
        end: new Date('2024-12-02T21:59:59.000Z'),
        name: 'Кіберпонеділок',
        slug: 'cyber-monday-2024',
        menuLabel: 'Акція до -70%'
      }, {
        start: new Date('2024-11-21T22:00:00.000Z'),
        end: new Date('2024-11-29T21:59:59.000Z'),
        name: 'Чорна п’ятниця',
        slug: 'black-friday-2024',
        menuLabel: 'Акція до -90%'
      }]
    };
  },
  getters: {
    /** @returns {Sale|false} */activeSale: function activeSale() {
      var cookie = useCookieStore();
      if (!cookie.isUkrainian) return false;
      var time = useTimeStore();
      var _iterator = _createForOfIteratorHelper(this.sales),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var sale = _step.value;
          if (sale.start <= time.now && time.now <= sale.end) return sale;
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return false;
    },
    /** @returns {Date|false} */start: function start() {
      /** @type {Sale|false} */
      var sale = this.activeSale;
      return sale && sale.start;
    },
    /** @returns {Date|false} */end: function end() {
      /** @type {Sale|false} */
      var sale = this.activeSale;
      return sale && sale.end;
    },
    /** @returns {string|false} */slug: function slug() {
      /** @type {Sale|false} */
      var sale = this.activeSale;
      return sale && sale.slug;
    },
    /** @returns {string|false} */utmLabel: function utmLabel() {
      /** @type {Sale|false} */
      var sale = this.activeSale;
      return sale && (sale.utmLabel || sale.slug);
    },
    /** @returns {string|false} */cssClass: function cssClass() {
      /** @type {Sale|false} */
      var sale = this.activeSale;
      return sale && (sale.class || sale.slug);
    },
    /** @returns {string|false} */name: function name() {
      /** @type {Sale|false} */
      var sale = this.activeSale;
      return sale && sale.name;
    },
    /** @returns {string|false} */menuLabel: function menuLabel() {
      /** @type {Sale|false} */
      var sale = this.activeSale;
      return sale && sale.menuLabel;
    },
    /** @returns {boolean} */isSaleActive: function isSaleActive() {
      /** @type {Sale|false} */
      var sale = this.activeSale;
      return !!sale;
    }
  }
});